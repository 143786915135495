import { PhoneNumberUtil } from 'google-libphonenumber'
import * as yup from 'yup'

const phoneUtil = PhoneNumberUtil.getInstance()

yup.addMethod(yup.string, 'phone', function () {
  return this.test('phone', async (value) => {
    if (value) {
      try {
        const number = phoneUtil.parse(value.replace(/[^\d+]/g, ''))
        return phoneUtil.isValidNumber(number)
      } catch (e) {}
    }
    return false
  })
})

export default yup
