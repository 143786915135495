import firebaseConfig from 'config/firebase'
import { RECAPTCHA_SITE_KEY } from 'config/app'
import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

let firebaseApp
const initFirebaseApp = () => {
  if (!firebaseApp) {
    firebaseApp = initializeApp(firebaseConfig)
    initializeAppCheck(firebaseApp, { provider: new ReCaptchaV3Provider(RECAPTCHA_SITE_KEY) })
  }

  return firebaseApp
}

export default initFirebaseApp
